import * as React from 'react';
import { GlobalStyle } from './src/styles/globalStyle';

export const wrapPageElement = ({ element }) => {
    return (
        <>
            <GlobalStyle />
            {element}
        </>
    );
};
